/*
 * 업무구분: 신탁
 * 파 일 명: TSAlertPopup.vue
 * 파일설명: Alert Modal Popup (공통)
 * 접근권한: 
 * 작 성 일: 2023.03.06
 * 작 성 자: 지재호
 */
<template>

  <!-- alert modal popup -->
  <mo-modal class="fts-modal small" ref="modalPopup" style="z-index:9500">
    <template v-if="type == 0 || type == 1">
      <span v-if="Array.isArray(content)">
        <span v-for="(row, i) in content" :key="i">
          {{ content[i] }}<br v-if="i < content.length"/>
        </span>
      </span>
      <span v-else>
        {{ content }}
      </span>
    </template>
    <template slot="action" v-if="type == 0">
      <div class="wrap-button button-page-bottom row">  
        <mo-button v-if="confirm && !single" point size="large" @click="fn_Close()">취소</mo-button>
        <mo-button v-if="confirm && !single" primary size="large" @click="fn_Confirm()">확인</mo-button>
        <mo-button v-if="!confirm" primary size="large" @click="fn_Close()">확인</mo-button>
        <mo-button v-if="confirm && single" primary size="large" @click="fn_Confirm()">확인</mo-button>
      </div>
    </template>
    <template slot="action" v-if="type == 1">
      <div class="wrap-button button-page-bottom row">          
        <mo-button v-if="confirm && cancel" size="large" @click="fn_Close()">아니오</mo-button>
        <mo-button v-if="confirm" primary size="large" @click="fn_Confirm()">예</mo-button>
      </div>
    </template>
    <template v-if="type == 7">
      <div class="column align-center">
        <h2 class="title" v-if="contentTitle">{{ contentTitle }}</h2>
        <span class="wrap-text">
          <span v-for="(row, i) in content" :key="i">
          {{ content[i] }}<br v-if="i < content.length"/>
          </span>
        </span>
      </div>
    </template>
    <template slot="action" v-if="type == 7">
      <div class="wrap-button button-page-bottom row">        
        <mo-button primary size="large" @click="confirm?fn_Confirm():fn_Close()">확인</mo-button>
      </div>
    </template>

    <template v-if="type == 8">
      <div class="column align-center">
        <h2 class="title" v-if="contentTitle">{{ contentTitle }}</h2>
        <span class="wrap-text">
          <span v-for="(row, i) in content" :key="i">
          {{ content[i] }}<br v-if="i < content.length"/>
          </span>
        </span>
      </div>
    </template>
    <template slot="action" v-if="type == 8">
      <div class="wrap-button button-page-bottom row" v-if="btnType == 0">
        <mo-button v-if="confirm && cancel" size="large" @click="fn_Close()">취소</mo-button>
        <mo-button primary size="large" @click="fn_Confirm()">확인</mo-button>
      </div>
      <div class="wrap-button button-page-bottom row" v-if="btnType == 1">
        <mo-button v-if="confirm && cancel" size="large" @click="fn_Close()">아니오</mo-button>
        <mo-button primary size="large" @click="fn_Confirm()">예</mo-button>
      </div>
      <div class="wrap-button button-page-bottom row" v-if="btnType == 2">
        <mo-button v-if="confirm && cancel" size="large" @click="fn_Close()">취소</mo-button>
        <mo-button primary size="large" @click="fn_Confirm()">이동</mo-button>
      </div>
      <div class="wrap-button button-page-bottom row" v-if="btnType == 3">
        <mo-button v-if="confirm && cancel" size="large" @click="fn_Close()">나가기</mo-button>
        <mo-button primary size="large" @click="fn_Confirm()">진행</mo-button>
      </div>
    </template>

    <template v-if="type == 9">
      <div class="column align-center">
        <h2 class="title" v-if="contentTitle">{{ contentTitle }}</h2>
        <span class="wrap-text">
          <span v-for="(row, i) in content" :key="i">
          {{ content[i] }}<br v-if="i < content.length"/>
          </span>
        </span>
      </div>
    </template>
    <template slot="action" v-if="type == 9">
      <div class="wrap-button button-page-bottom row" v-if="btnType == 0">
        <!-- <mo-button v-if="confirm && cancel" size="large" @click="fn_Close()">취소</mo-button> -->
        <mo-button primary size="large" @click="fn_SelfAthnt()">확인</mo-button>
      </div>
      <div class="wrap-button button-page-bottom row" v-if="btnType == 1">
        <mo-button v-if="confirm && cancel" size="large" @click="fn_Close()">아니오</mo-button>
        <mo-button primary size="large" @click="fn_SelfAthnt()">예</mo-button>
      </div>
      <div class="wrap-button button-page-bottom row" v-if="btnType == 2">
        <mo-button v-if="confirm && cancel" size="large" @click="fn_Close()">취소</mo-button>
        <mo-button primary size="large" @click="fn_SelfAthnt()">이동</mo-button>
      </div>
    </template>
    <!-- popup307 (고객보관서류선택) -->
    <msp-fs-307p
      ref="popup307"
      @fs-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopup307Obj"
      v-if="type == 9"
    ></msp-fs-307p>

    <!-- popup510 (본인인증) -->
    <msp-fs-510p
      ref="popup510"        
      @fs-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopup510Obj"
      v-if="type == 9"
    ></msp-fs-510p>
  </mo-modal>

</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
  import MSPFS307P from '~/src/ui/fs/MSPFS307P'  // 고객보관서류선택
  import MSPFS510P from '~/src/ui/fs/MSPFS510P'  // 본인인증  

  export default {
    name: 'AlertPopup',
    screenId: 'MSPTSAlertPopup',
    props: {
      popupObj: {type:Object, default:null},
    },
    components: {
      'msp-fs-307p': MSPFS307P,
      'msp-fs-510p': MSPFS510P,
    },
    data() {
      return {
        type: 0,
        pPopup307Obj: {}, // 고객보관서류선택 팝업 객체
        pPopup510Obj: {}, // 본인인증 팝업 객체 
        cancel: true,        
        confirmData: '',
        closeData: '',
        ptitle : '',
        contentTitle: '',
        btnType: 0,
        confirm: false,
        content: '',
        single : false,
        closeType: '',
      }
    },
    computed: {
      modalPopup() {
        return this.$refs.modalPopup
      }
    },
    mounted () {
    },
    methods: {

      fn_Open() {
        if( !TSCommUtil.gfn_isNull(this.popupObj) ) {          
          this.single  = TSCommUtil.gfn_isNull (this.popupObj.single ) ? false : this.popupObj.single

          this.ptitle  = this.popupObj.ptitle
          this.confirm = TSCommUtil.gfn_isNull (this.popupObj.confirm) ? false : this.popupObj.confirm
          this.cancel  = TSCommUtil.gfn_isNull(this.popupObj.cancel)  ? true : this.popupObj.cancel
          this.content = this.popupObj.content
          this.type    = 0
          this.contentTitle = this.popupObj.contentTitle
          this.btnType = 0

          if( !TSCommUtil.gfn_isNull(this.popupObj.closeData) ){
            this.closeData = this.popupObj.closeData
          }
          if( !TSCommUtil.gfn_isNull(this.popupObj.confirmData) ){            
            this.confirmData = this.popupObj.confirmData

            this.$bizUtil.tsUtils.saveCvrPblParam(this, {
              tacno  : !TSCommUtil.gfn_isNull(this.confirmData.tacno)   ? this.confirmData.tacno : '',
              bnkbSeq: !TSCommUtil.gfn_isNull(this.confirmData.bnkbSeq) ? this.confirmData.bnkbSeq : '',
              accSeq : !TSCommUtil.gfn_isNull(this.confirmData.accSeq)  ? this.confirmData.accSeq : '',
              csId   : !TSCommUtil.gfn_isNull(this.confirmData.csId)  ? this.confirmData.csId : '',
              cvrTypC : !TSCommUtil.gfn_isNull(this.confirmData.cvrTypC)  ? this.confirmData.cvrTypC : '',
            })
          }
          if( !TSCommUtil.gfn_isNull(this.popupObj.type) && this.popupObj.type != 0 ){
            this.type = this.popupObj.type

            if(this.type === 9){
              let msg = '본인인증 후 전자서명을 진행합니다.'
              this.content.push(msg)
            }
          }
          if( !TSCommUtil.gfn_isNull(this.popupObj.btnType) && this.popupObj.btnType != 0 ){
            this.btnType = this.popupObj.btnType
          }
        }

        this.modalPopup.open();
      },
      fn_OpenA(pObj) {

        if (this.popupObj==undefined || this.popupObj==null){
          this.popupObj = {};
        }
        if (pObj.confirmFunc!=undefined && pObj.confirmFunc!=null){
          this.popupObj.confirmFunc = pObj.confirmFunc;
        }
        if (pObj.closeFunc!=undefined && pObj.closeFunc!=null){
          this.popupObj.closeFunc = pObj.closeFunc;
        }
        this.single  = TSCommUtil.gfn_isNull (pObj.single ) ? false : pObj.single

        this.ptitle  = pObj.ptitle
        this.confirm = TSCommUtil.gfn_isNull (pObj.confirm) ? false : pObj.confirm
        this.cancel  = TSCommUtil.gfn_isNull(pObj.cancel)  ? true : pObj.cancel
        this.content = pObj.content
        this.type    = 0
        this.contentTitle = pObj.contentTitle
        this.btnType = 0
        this.closeType = "";

        if( !TSCommUtil.gfn_isNull(pObj.closeData) ){
          this.closeData = pObj.closeData
        }
        if( !TSCommUtil.gfn_isNull(pObj.confirmData) ){          
          this.confirmData = pObj.confirmData
          this.$bizUtil.tsUtils.saveCvrPblParam(this, {
            tacno  : !TSCommUtil.gfn_isNull(this.confirmData.tacno)   ? this.confirmData.tacno : '',
            bnkbSeq: !TSCommUtil.gfn_isNull(this.confirmData.bnkbSeq) ? this.confirmData.bnkbSeq : '',
            accSeq : !TSCommUtil.gfn_isNull(this.confirmData.accSeq)  ? this.confirmData.accSeq : '',
            csId   : !TSCommUtil.gfn_isNull(this.confirmData.csId)  ? this.confirmData.csId : '',
            cvrTypC : !TSCommUtil.gfn_isNull(this.confirmData.cvrTypC)  ? this.confirmData.cvrTypC : '',
          })
        }
        if( !TSCommUtil.gfn_isNull(pObj.type) && pObj.type != 0 ){
          this.type = pObj.type

          if(this.type === 9){
            let msg = '본인인증 후 전자서명을 진행합니다.'
            this.content.push(msg)
          }
        }
        if( !TSCommUtil.gfn_isNull(pObj.btnType) && pObj.btnType != 0 ){
          this.btnType = pObj.btnType
        }

        this.modalPopup.open();
      },
      fn_Close() {
        this.closeType = "N";
        this.modalPopup.close();
        if( !TSCommUtil.gfn_isNull(this.popupObj.closeFunc) ) {
          this.popupObj.closeFunc.call(this, this.closeData);
        }        
      },

      fn_Confirm() {
        this.closeType = "Y";
        this.modalPopup.close();
        if( !TSCommUtil.gfn_isNull(this.popupObj.confirmFunc) ) {
          this.popupObj.confirmFunc.call(this, this.confirmData);
        }
      },

      async fn_SelfAthnt() {
        this.modalPopup.close();
        if( !TSCommUtil.gfn_isNull(this.popupObj.confirmFunc) ) {
          let isCstCstd = this.getStore('tsStore').getters.getState.isCstCstd          
          if (!isCstCstd) {
            this.getStore('tsStore').dispatch('IS_CSTCSTD_START')            
            await this.popupObj.confirmFunc.call(this)
            this.$refs.popup510.fn_Open()
          }
        }
      },

      /******************************************************************************
      * Function명 : fn_Popup_CallBack
      * 설명       : popup 컴포넌트에서 callback
      * 파라미터   : 호출한 페이지 파라미터
      ******************************************************************************/
      fn_Popup_CallBack(type, pData) {
        switch (type) {          
          case 'MSPFS307P': // 고객보관서류 선택
            console.log('고객보관서류 선택 callback...', pData)
            if(!TSCommUtil.gfn_isNull(pData)) { // 고객보관서류 선택 시
              this.$bizUtil.tsUtils.saveSendMth(this, {
                emailYN: pData.emailYN,
                cellPhoneYN:  pData.cellPhoneYN,
                postYN:  pData.postYN,
                email01:  pData.email01,
                email02:  pData.email02,
                cellPhone01:  pData.cellPhone01,
                cellPhone02:  pData.cellPhone02,
                cellPhone03:  pData.cellPhone03,
              })
              if( !TSCommUtil.gfn_isNull(this.popupObj.confirmFunc) ) {                
                this.popupObj.confirmFunc.call(this)
              }
            }
            break

          case 'MSPFS510P': // 본인인증
            console.log('본인인증 callback...', pData)
            if(pData) { // 본인인증 정상처리 시
              if( !TSCommUtil.gfn_isNull(this.popupObj.confirmFunc) ) {                
                let docTyp = this.$bizUtil.tsUtils.getDocTyp(this)
                if(docTyp.isCust) {                  
                  // if( !TSCommUtil.gfn_isNull(this.popupObj.confirmData)) {
                  //   this.pPopup307Obj.csId    = this.popupObj.confirmData.csId
                  //   this.pPopup307Obj.tacno   = this.popupObj.confirmData.tacno
                  //   this.pPopup307Obj.bnkbSeq = this.popupObj.confirmData.bnkbSeq
                  //   this.pPopup307Obj.accSeq  = this.popupObj.confirmData.accSeq
                  // }

                  try {
                    let cvrPblParam = this.getStore('tsStore').getters.getBasInfo.data.cvrPblParam
                    if( !TSCommUtil.gfn_isNull(cvrPblParam) ) {
                      this.pPopup307Obj.csId    = cvrPblParam.csId
                      this.pPopup307Obj.tacno   = cvrPblParam.tacno
                      this.pPopup307Obj.bnkbSeq = cvrPblParam.bnkbSeq
                      this.pPopup307Obj.accSeq  = cvrPblParam.accSeq                        
                    } else {
                      
                    }
                  } catch (e) {                      
                  }
                  this.$refs.popup307.fn_Open()
                } else {
                  console.log('고객보관 서류 없음')
                  this.popupObj.confirmFunc.call(this)
                }
              }
            }
            break
        }
      },    

    }
  };
</script>